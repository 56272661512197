import './index.scss';
import $ from "jquery";

window.cards = {}
window.cards.setBubbleState = (e)=>{
    console.log(e)
}

$(document).ready(function(){
    let updateGallery = function(block){
        let container = block.parents(".wp-block-bootstrap-container");
        container.find(".is-selected").removeClass("is-selected");
        block.addClass("is-selected");
        let $img = block.find("img.card-image");
        console.log(block,$img);
        if (!$img.length){
            return;
        }
        let css = {
            backgroundImage : "url("+$img.attr("src")+")",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            opacity: 0.3
        };
        if (!container.children(".position-background").length){
            container.prepend('<div class="position-background"></div>')
        }
        let bg =  container.children(".position-background");
        bg.css(css);

    }

    $(document).on("mouseenter", '.wp-block-webfactory-card[data-template="gallery-item"]', function(e){
        updateGallery($(this));
    });
    $('.wp-block-bootstrap-row > .wp-block-bootstrap-column:first-child > .wp-block-webfactory-card[data-template="gallery-item"]').each(function(){
        updateGallery($(this))
    })
})

$.fn.webfactoryCarouselTestimonyCard = function(){
  $(this).show();
	$(this).slick({    
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      swipe: true,
  });
}

$(document).ready(function(){
    /* 
  popup share 
*/
  $(".view-modal").on("click", function() {
      const popup = $(this).parent().find(".popup");
      popup.toggleClass("show");
    });
    
    $(".close").on("click", function() {
      $(this).parent().parent().removeClass("show");
    });
    
    $(".copy").on("click", function() {
      const input = $(this).parent().find('input');
      const inputValue = input.val();
      const tempElem = $('<textarea>');
      tempElem.val(inputValue);
      $('body').append(tempElem);
      tempElem.select();
      document.execCommand('copy');
      tempElem.remove();

      const tooltip = $(this).parent().find('.tooltip');
        tooltip.addClass('show');
        setTimeout(() => {
          tooltip.removeClass('show');
        }, 2000);
    });

    
    $('.wp-block-webfactory-carousel[data-template="testimony"]').find('[data-layout="slick-carousel"]:not(.slick-initialized)').each(function(){		
      $(this).webfactoryCarouselTestimonyCard();
    });

    setInterval(function(){
      $('.has-animation .animation--cicle-stroke').removeClass('has-animation')
    },1000)

});

$(document).ready(function(){
  $('.is-with-adjust-height').each(function(){
      $(this).first().parent().addClass("height-unset");
      
  });
});

$(document).ready(function() {
  if ( ($('body').find('.wp-block-webfactory-card[data-template="modern"]').length > 0) && (window.innerWidth <= 540) ) {
    let lastClickedDiv = null;  
    $('.wp-block-webfactory-card[data-template="modern"]').each(function(){
        $(this).click(function(e) {
              e.preventDefault();
              if (lastClickedDiv === this) {
                window.location.href = $(this).find("a").attr("href");
              }
              else {
                lastClickedDiv = this;
              }
          })
      }) 
      
  }
});


